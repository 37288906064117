import { Transform } from 'class-transformer'
import { IsBoolean, IsOptional } from 'class-validator'

export class ExportFeaturesDTO {
  @IsOptional()
  @IsBoolean()
  @Transform(({ value }) => value === 'true')
  includeOverviewLevel?: boolean

  @IsOptional()
  @IsBoolean()
  @Transform(({ value }) => value === 'true')
  includeDots?: boolean

  @IsOptional()
  @IsBoolean()
  @Transform(({ value }) => value === 'true')
  includeFlows?: boolean

  @IsOptional()
  @IsBoolean()
  @Transform(({ value }) => value === 'true')
  includeKeys?: boolean

  @IsOptional()
  @IsBoolean()
  @Transform(({ value }) => value === 'true')
  includeLabels?: boolean

  @IsOptional()
  @IsBoolean()
  @Transform(({ value }) => value === 'true')
  includeZones?: boolean

  constructor() {
    this.includeOverviewLevel = false
    this.includeDots = false
    this.includeFlows = false
    this.includeKeys = false
    this.includeLabels = false
    this.includeZones = false
  }
}

export class LayerFeaturesExportModel {
  title: string
  eventId: string
  eventName: string
  venueId: string
  venueName: string
  siteId: string
  siteName: string
  levels: Record<string, string>
  keys?: KeyExportData[]
  flows?: FlowExportData[]
  dots?: DotExportData[]
  labels?: LabelExportData[]
  zones?: ZoneExportData[]
}

export class KeyExportData {
  key?: string
  projects?: string[]
  scope?: string
  shortNote?: string
  displayNote?: string
  area?: number
  perimeter?: number
  activityPeriods?: string
  observations?: string
  documents?: number
  createdBy?: string
  createdAt?: string
  updatedBy?: string
  updatedAt?: string
  level?: string
  venue?: string
  site?: string
  event?: string
}

export class FlowExportData {
  projects?: string[]
  label?: string
  scope?: string
  flow?: string
  symbology?: string
  activityPeriods?: string
  observations?: string
  documents?: number
  length?: number
  createdBy?: string
  createdAt?: string
  updatedBy?: string
  updatedAt?: string
  level?: string
  venue?: string
  site?: string
  event?: string
}

export class DotExportData {
  projects?: string[]
  label?: string
  scope?: string
  dotPlanSymbology?: string
  rotation?: number
  activityPeriods?: string
  supplier?: string
  observations?: string
  documents?: number
  createdBy?: string
  createdAt?: string
  updatedBy?: string
  updatedAt?: string
  level?: string
  venue?: string
  site?: string
  event?: string
}

export class LabelExportData {
  key?: string
  projects?: string[]
  label?: string
  scope?: string
  activityPeriods?: string
  size?: number
  rotation?: number
  font?: string
  style?: string
  weight?: string
  haloColor?: string
  haloRadius?: string
  createdBy?: string
  createdAt?: string
  updatedBy?: string
  updatedAt?: string
  level?: string
  venue?: string
  site?: string
  event?: string
}

export class ZoneExportData {
  projects?: string[]
  label?: string
  scope?: string
  perimeterSymbology?: number
  symbology?: string
  activityPeriods?: string
  observations?: string
  documents?: number
  area?: number
  perimeter?: number
  createdBy?: string
  createdAt?: string
  updatedBy?: string
  updatedAt?: string
  level?: string
  venue?: string
  site?: string
  event?: string
}
