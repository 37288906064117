import { IsNotEmpty, IsNumber, IsNumberString, IsOptional, IsUUID } from 'class-validator'
import { ArrayParse, BooleanParse, NumberParse } from '../decorators'
import { ProjectDTO } from '../project'

/* eslint-disable @typescript-eslint/camelcase */
export enum PanoramaImageStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  DONE = 'done',
}

export interface PanoramaProperties {
  filename: string
  latitude: number
  longitude: number
  status: PanoramaImageStatus
  restrict_projects: boolean
  canManage?: boolean
}

export class PanoramaTileSearch {
  @IsNumberString()
  @IsNotEmpty()
  zoom: string

  @IsNumberString()
  @IsNotEmpty()
  x: string

  @IsNumberString()
  @IsNotEmpty()
  y: string
}

export class PanoramaUpdateDTO {
  @IsUUID()
  siteId?: string

  @IsUUID()
  venueId?: string

  @IsNotEmpty()
  levelId: string

  @IsNumber()
  @IsNotEmpty()
  @NumberParse()
  latitude: number

  @IsNumber()
  @IsNotEmpty()
  @NumberParse()
  longitude: number

  @IsOptional()
  @ArrayParse()
  projects: string[] = []

  @IsOptional()
  @BooleanParse()
  restricted?: boolean
}

export class PanoramaCreateDTO {
  siteId?: string

  venueId?: string

  @IsNotEmpty()
  levelId: string

  @IsNumber()
  @IsNotEmpty()
  @NumberParse()
  latitude: number

  @IsNumber()
  @IsNotEmpty()
  @NumberParse()
  longitude: number

  @IsOptional()
  @ArrayParse()
  projects: string[]

  @IsOptional()
  @BooleanParse()
  restricted?: boolean
}

export class PanoramaDTO {
  fileName: string
  restricted: boolean
  projects: ProjectDTO[]
  levelId: string
}
