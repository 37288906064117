import { IsArray, IsBoolean, IsEnum, IsNotEmpty, IsNumberString, IsOptional, IsUUID, ValidateIf } from 'class-validator'
import { ArrayParse, BooleanParse, NumberRound, StringTrim } from '../decorators'
import { GeoFilterDTO } from '../level-facility/index'
import { BaseParametersDTO } from '../shared'
import { PaginatedSearchDTO } from '../shared/paginated-search.dto'
import { VistaDTO, VistaListDTO } from '../shared/vista.dto'
import { LevelLayerType } from '../site/site.dto'

export class LayerListDTO extends VistaListDTO {
  displayName?: string
  layerType: LevelLayerType
  layerTypeId: string
  venueId: string
  venueName: string
  siteId: string
  siteName: string
  levelId?: string
  levelName?: string
  eventIds?: string[]
  eventNames?: string[]
  eventCycleNames?: string[]
  eventCycleIds?: string[]
  synchronized?: boolean
}

export class LayerDTO extends VistaDTO {
  displayName?: string
  country: string
  layerType: LevelLayerType
  layerTypeId: string
  venueId: string
  venueName: string
  siteId: string
  siteName: string
  levelId?: string
  levelName?: string
  eventId?: string
  eventCycleId?: string
  eventName?: string
  order: number
  numberOfFeatures: number
  synchronized?: boolean

  // Only for blueprints
  allEvents?: boolean
  eventIds?: string[]
  allEventCycles?: boolean
  eventCycleIds?: string[]
}

export class LevelLayerTypeUtils {
  public static getAll(): LevelLayerType[] {
    return Object.values(LevelLayerType).filter(t => t !== LevelLayerType.FACILITIES && t !== LevelLayerType.PANORAMA)
  }

  // For now the layer creation endpoint only support the layer types below
  public static getForCreate(): LevelLayerType[] {
    return [
      LevelLayerType.BLUEPRINT,
      LevelLayerType.KEYS,
      LevelLayerType.ACCS_Dots,
      LevelLayerType.ACCS_Flows,
      LevelLayerType.ACCS_Zoning,
      LevelLayerType.LABELS,
    ]
  }

  public static getForCopy(): LevelLayerType[] {
    return [LevelLayerType.KEYS, LevelLayerType.ACCS_Dots, LevelLayerType.ACCS_Flows, LevelLayerType.ACCS_Zoning, LevelLayerType.LABELS]
  }

  // layer types that makes eventId mandatory
  public static isRequireEvent(type: LevelLayerType): boolean {
    return type && type !== LevelLayerType.BLUEPRINT
  }

  public static getLabel(layerType: LevelLayerType) {
    switch (layerType) {
      case LevelLayerType.ACCS_Dots:
        return 'Dots'
      case LevelLayerType.ACCS_Flows:
        return 'Flows'
      case LevelLayerType.ACCS_Zoning:
        return 'Zoning'
      case LevelLayerType.BLUEPRINT:
        return 'Blueprint'
      case LevelLayerType.FACILITIES:
        return 'Facilities'
      case LevelLayerType.KEYS:
        return 'Keys'
      case LevelLayerType.LABELS:
        return 'Labels'
      default:
        return ''
    }
  }

  public static getShortLabel(layerType: LevelLayerType) {
    switch (layerType) {
      case LevelLayerType.ACCS_Dots:
        return 'DT'
      case LevelLayerType.ACCS_Flows:
        return 'FL'
      case LevelLayerType.ACCS_Zoning:
        return 'ZN'
      case LevelLayerType.BLUEPRINT:
        return 'BP'
      case LevelLayerType.FACILITIES:
        return 'FC'
      case LevelLayerType.KEYS:
        return 'K'
      case LevelLayerType.LABELS:
        return 'L'
      default:
        return ''
    }
  }

  public static getOrder(layerType: LevelLayerType, inverse?: boolean): number {
    const orderedLayers = [
      LevelLayerType.BLUEPRINT,
      LevelLayerType.KEYS,
      LevelLayerType.ACCS_Zoning,
      LevelLayerType.ACCS_Flows,
      LevelLayerType.ACCS_Dots,
      LevelLayerType.LABELS,
      LevelLayerType.FACILITIES,
    ]

    let order = orderedLayers.indexOf(layerType)
    if (inverse) {
      order = orderedLayers.length - order
    }

    return order
  }

  public static canHavePanoramas(layerType: LevelLayerType) {
    return [LevelLayerType.KEYS, LevelLayerType.ACCS_Zoning].includes(layerType)
  }

  public static getPanoramaLayerName(levelId: string) {
    return `${LevelLayerType.PANORAMA}-${levelId}`
  }
}

export class LayerUpdateDTO {
  @ValidateIf(layer => !layer.eventCycleId && LevelLayerTypeUtils.isRequireEvent(layer.layerType))
  @IsNotEmpty()
  @StringTrim()
  eventId: string

  @ValidateIf(layer => !layer.eventId && LevelLayerTypeUtils.isRequireEvent(layer.layerType))
  @IsNotEmpty()
  @StringTrim()
  eventCycleId?: string

  @IsUUID()
  @IsOptional()
  levelId: string

  layerTypeId?: string

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  synchronized?: boolean

  // Only for blueprints
  @IsOptional()
  @ArrayParse()
  eventIds?: string[]

  // Only for blueprints
  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  allEvents?: boolean

  // Only for blueprints
  @IsOptional()
  @ArrayParse()
  eventCycleIds?: string[]

  // Only for blueprints
  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  allEventCycles?: boolean

  constructor(model?: LayerDTO) {
    this.allEvents = true
    this.allEventCycles = false

    if (model) {
      this.levelId = model.levelId || 'ov'
      this.eventId = model.eventId
      this.eventIds = model.eventIds
      this.allEvents = model.allEvents
      this.eventCycleIds = model.eventCycleIds
      this.allEventCycles = model.allEventCycles
      this.layerTypeId = model.layerTypeId
      this.synchronized = model.synchronized
    }
  }
}

export class BlueprintLayerImpactSearchDTO {
  createModel: LayerCreateDTO
}

export class BlueprintLayerImpactedEventDTO {}

export class LayerCreateDTO extends LayerUpdateDTO {
  @IsNotEmpty()
  layerType: LevelLayerType

  @IsNotEmpty()
  @IsUUID()
  venueId: string

  @IsOptional()
  @IsUUID()
  siteId: string

  @ArrayParse()
  @IsOptional()
  eventIdsToLock?: string[]

  layerTypeId?: string

  constructor(model?: LayerDTO) {
    super(model)
    if (model) {
      this.layerType = model.layerType
      this.layerTypeId = model.layerTypeId
      this.siteId = model.siteId
      this.venueId = model.venueId
    }
  }
}

export enum LayerSearchSortField {
  NAME = 'displayName',
  LAYER_TYPE = 'layerType',
  LAYER_TYPE_ORDER = 'layerTypeOrder',
  VENUE = 'venueName',
  SITE = 'siteName',
  SITE_LEVEL = 'levelName',
  EVENT = 'eventNames',
  CREATE_DATE = 'createdAt',
  EVENT_CYCLE = 'eventCycleNames',
}

export class LayerSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsUUID()
  eventCycleId?: string

  @IsOptional()
  @IsUUID()
  eventId?: string

  @IsOptional()
  @IsUUID()
  siteId?: string

  @IsOptional()
  @IsUUID()
  venueId?: string

  @IsOptional()
  @IsUUID()
  levelId?: string

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  overview?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  includeSiteLayers?: boolean

  @IsOptional()
  layerType?: LevelLayerType

  @IsOptional()
  @ArrayParse()
  layerTypeIds?: string[]

  @IsOptional()
  @IsEnum(LayerSearchSortField)
  sortBy?: string
}

export class GeoFeatureFilterDTO extends GeoFilterDTO {
  @IsNotEmpty()
  layerType: LevelLayerType

  @IsNotEmpty()
  @IsUUID()
  venueId: string

  @IsOptional()
  siteId: string

  @IsOptional()
  eventId: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  levels: string[]

  @IsOptional()
  @BooleanParse()
  overview?: boolean

  @IsOptional()
  featureId?: string

  @IsOptional()
  @BooleanParse()
  withStyles?: boolean

  @IsOptional()
  styleName?: string

  @IsOptional()
  @BooleanParse()
  withCache?: boolean
}

export class GeoFeatureLocationFilterDTO extends GeoFilterDTO {
  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  layerTypes: LevelLayerType[]

  @IsNotEmpty()
  @IsUUID()
  venueId: string

  @IsOptional()
  siteId: string

  @IsOptional()
  eventId: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  levels: string[]

  @IsOptional()
  @BooleanParse()
  overview?: boolean

  @IsOptional()
  featureId?: string

  @IsOptional()
  @BooleanParse()
  withStyles?: boolean
}

export class LayerFeatureTileOptionsDTO extends GeoFeatureFilterDTO {
  @IsNotEmpty()
  bbox: string

  @IsNotEmpty()
  @IsNumberString()
  width: number

  @IsNotEmpty()
  @IsNumberString()
  height: number

  @IsOptional()
  srs?: string
}

export class LayerTileOptionsDTO extends GeoFilterDTO {
  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  layerName: string[]

  @IsNotEmpty()
  bbox: string

  @IsNotEmpty()
  @IsNumberString()
  width: number

  @IsNotEmpty()
  @IsNumberString()
  height: number

  @IsOptional()
  featureId?: string

  @IsOptional()
  @IsEnum(LevelLayerType)
  layerType?: LevelLayerType

  @IsUUID()
  @IsOptional()
  levelId?: string

  @IsOptional()
  @BooleanParse()
  withStyles?: boolean
}

export class PanoramaTileOptionsDTO extends GeoFilterDTO {
  @IsNotEmpty()
  bbox: string

  @IsNotEmpty()
  @IsNumberString()
  width: number

  @IsNotEmpty()
  @IsNumberString()
  height: number

  @IsOptional()
  featureId?: string

  @IsUUID()
  @IsOptional()
  levelId?: string

  @IsBoolean()
  @BooleanParse()
  @IsOptional()
  isOverview?: boolean

  @IsUUID()
  @IsOptional()
  venueId?: string

  @IsOptional()
  @BooleanParse()
  withStyles?: boolean

  @IsOptional()
  srs?: string

  @IsOptional()
  styleName?: string
}

export class CachedLayerTileOptionsDTO {
  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  layerName: string[]

  @IsNotEmpty()
  @IsNumberString()
  @NumberRound()
  zoom: number

  @IsNotEmpty()
  @IsNumberString()
  x: number

  @IsNotEmpty()
  @IsNumberString()
  y: number
}
export class LayerCopyParametersDTO extends BaseParametersDTO {
  @IsUUID()
  sourceId: string
}

export class FacilityMapOptionsDTO {
  @IsNotEmpty()
  @IsNumberString()
  width: number

  @IsNotEmpty()
  @IsNumberString()
  height: number
}

export class LayerDataOptions {
  @IsNotEmpty()
  layerTypeShortName: string

  @IsOptional()
  @ArrayParse()
  subEventIds?: string[]

  @IsOptional()
  @ArrayParse()
  templateIds?: string[]
}
