import { IsArray, IsBoolean, IsEnum, IsOptional } from 'class-validator'
import { ArrayParse, BooleanParse } from '../decorators'
import { PaginatedSearchDTO } from '../shared/paginated-search.dto'

export enum VenueSortField {
  NAME = 'name',
  COUNTRY = 'country',
  REGION = 'region',
  PROVINCE = 'province',
}

export class VenueGetByIdOptions {
  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  allEvents?: boolean
}

export class VenueSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventCycleIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIntegrationIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  siteIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  siteTypeIds?: string[]

  region?: string

  country?: string

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  allEvents?: boolean = true

  @IsOptional()
  @IsEnum(VenueSortField)
  sortBy?: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  applications?: Array<string>
}
