import { Type } from 'class-transformer'
import { IsArray, IsBoolean, IsDateString, IsEnum, IsNotEmpty, IsOptional } from 'class-validator'
import { ArrayParse, BooleanParse, StringTrim } from '../decorators'
import { PaginatedSearchDTO } from '../shared/paginated-search.dto'

export enum MatchSearchSortField {
  Name = 'name',
  Venue = 'venue',
  Site = 'site',
  MatchDayName = 'matchDayName',
  HomeTeam = 'homeTeam',
  AwayTeam = 'awayTeam',
  NumberOfContacts = 'numberOfContacts',
  NumberOfActivitiesInTimeline = 'numberOfActivitiesInTimeline',
  NumberOfDocuments = 'numberOfDocuments',
  NumberOfAnnouncements = 'numberOfAnnouncements',
  LastModified = 'lastModified',
  DateMatchUtc = 'dateMatchUtc',
}

export class MatchSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  homeTeamIntegrationIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  homeTeamIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  awayTeamIntegrationIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  awayTeamIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  siteIntegrationIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  siteIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  venueIntegrationIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  venueIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  competitionIntegrationIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  competitionIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  subEventIntegrationIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  subEventIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: Array<string>

  @IsOptional()
  @Type(() => Date)
  dateMatchStart?: Date

  @IsOptional()
  @Type(() => Date)
  dateMatchEnd?: Date

  @IsOptional()
  @Type(() => Date)
  dateMatchUtc?: Date

  @IsOptional()
  @Type(() => Date)
  timeMatchUtc?: Date

  @IsOptional()
  @StringTrim()
  matchDaySearch?: string

  @IsOptional()
  @IsEnum(MatchSearchSortField)
  sortBy?: string

  /**
   * Filter to return only the matches that are live  (between MD-1 and MD + 4 hours)
   */
  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  onlyLiveMatches?: boolean

  @IsOptional()
  @IsArray()
  @ArrayParse()
  matchDayIds?: Array<string>
}

export class MatchDaySearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  venueIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  siteIds?: Array<string>
}

export class MatchUpdatesSearchDTO extends MatchSearchDTO {
  @IsDateString()
  @IsNotEmpty()
  sinceDate?: Date
}
