import { Transform } from 'class-transformer'
import { IsArray, IsBoolean, IsDate, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsUUID } from 'class-validator'
import { ArrayParse, BooleanParse, NumberParse } from '../decorators'
import { LevelLayerType } from '../site'

export class GeoFilterDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  keyIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  projectIds?: string[]

  @IsOptional()
  @Transform(({ value }) => (['number', 'string'].includes(typeof value) ? new Date(+value || value) : value))
  @IsDate()
  intervalStartDate?: Date

  @IsOptional()
  @Transform(({ value }) => (['number', 'string'].includes(typeof value) ? new Date(+value || value) : value))
  @IsDate()
  intervalEndDate?: Date

  @IsOptional()
  @IsArray()
  @ArrayParse()
  dotScopes?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  flowScopes?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  zoneScopes?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  keyScopes?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  labelScopes?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  dotSymbologies?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  flowSymbologies?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  zoneSymbologies?: string[]

  @IsOptional()
  keySearch?: string

  @IsOptional()
  facilityId?: string
}

export class FacilitySearchDTO extends GeoFilterDTO {
  siteId: string
  eventId: string
  venueId?: string
  levelId?: string
  facilityId?: string
  includeOverview?: boolean
  latitude?: number
  longitude?: number
  types?: LevelLayerType[]
  zoom?: number
  withTypeFilter?: boolean
}
export class ManyFacilitiesSearchDTO {
  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  siteEventPairs: string[]

  @IsOptional()
  @BooleanParse()
  includeOverview: boolean

  @IsOptional()
  @IsArray()
  @ArrayParse()
  keyIds?: string[]

  @IsOptional()
  @BooleanParse()
  withNotes?: boolean
}

export class LevelFacilitySearchDTO extends GeoFilterDTO {
  @IsOptional()
  facilityId: string

  @IsOptional()
  keySearch?: string

  @IsUUID()
  @IsOptional()
  levelId?: string

  @IsOptional()
  @BooleanParse()
  includeOverview: boolean

  @IsOptional()
  @IsEnum(LevelLayerType, { each: true })
  @ArrayParse()
  layerTypes: LevelLayerType[]
}

export class LevelFacilityByLocationSearchDTO extends GeoFilterDTO {
  @IsNotEmpty()
  @IsNumber()
  @NumberParse()
  latitude: number

  @IsNotEmpty()
  @IsNumber()
  @NumberParse()
  longitude: number

  @IsUUID()
  @IsNotEmpty()
  levelId: string

  @IsOptional()
  @BooleanParse()
  includeOverview: boolean

  @IsOptional()
  @IsEnum(LevelLayerType, { each: true })
  @ArrayParse()
  types: LevelLayerType[]

  @IsOptional()
  @IsNumber()
  @NumberParse()
  zoom?: number

  @IsOptional()
  @BooleanParse()
  withTypeFilter?: boolean
}

export class PanoramaByLocationSearchDTO extends GeoFilterDTO {
  @IsNotEmpty()
  @IsNumber()
  @NumberParse()
  latitude: number

  @IsNotEmpty()
  @IsNumber()
  @NumberParse()
  longitude: number

  @IsUUID()
  @IsNotEmpty()
  levelId: string

  @IsOptional()
  @IsNumber()
  @NumberParse()
  zoom?: number
}

export class PanoramaFeatureByLocationSearchDTO extends GeoFilterDTO {
  @IsNotEmpty()
  @IsNumber()
  @NumberParse()
  latitude: number

  @IsNotEmpty()
  @IsNumber()
  @NumberParse()
  longitude: number

  @IsUUID()
  @IsOptional()
  levelId?: string

  @IsBoolean()
  @BooleanParse()
  @IsOptional()
  overview?: boolean

  @IsUUID()
  @IsNotEmpty()
  venueId: string

  @IsOptional()
  @IsNumber()
  @NumberParse()
  zoom?: number
}

export class LevelFacilityByPolygonSearchDTO extends GeoFilterDTO {
  @IsUUID()
  @IsNotEmpty()
  levelId: string

  @IsNotEmpty()
  facilityId: string
}
export class LevelFacilityFeatureByPolygonSearchDTO extends GeoFilterDTO {
  @IsUUID()
  @IsNotEmpty()
  levelId: string

  @IsUUID()
  @IsNotEmpty()
  venueId: string

  @IsNotEmpty()
  facilityId: string
}

export class LevelFacilityByIdSearchDTO {
  @IsOptional()
  @BooleanParse()
  includeOverview: boolean
}
